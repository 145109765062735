import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import BigLogo from "../components/biglogo"
import Apple from "../components/apple"
import Spotify from "../components/spotify"
import Google from "../components/google"
import LinkedIn from "../components/linkedin"
import Twitter from "../components/twitter"
import Thomas from "../components/thomas"
import Federico from "../components/federico"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Bitcoin Cabana Podcast" />

      <div style={{ maxWidth: `600px`, marginBottom: `1.45rem`, margin: `0 auto`}}>
        <BigLogo />

        <div style={{marginBottom: `2.9rem`, marginTop: `2.9rem`}}>
          <div style={{ float: `left`, width: `33%`, padding: `0 0.5rem` }}>
            <div style={{maxWidth: `200px`, margin: `0 auto`}}>
              <a href="https://podcasts.apple.com/it/podcast/bitcoin-cabana/id1561631462" target="_blank" rel="noreferrer"><Apple/></a>
            </div>
          </div>
          <div style={{ float: `left`, width: `33%`, padding: `0 0.5rem`}}>
            <div style={{maxWidth: `200px`, margin: `0 auto`}}>
              <a href="https://open.spotify.com/show/2GqAcWtIJ7Yf2FXspe6zk6?si=fef18c104e124280" target="_blank" rel="noreferrer"><Spotify/></a>
            </div>
          </div>
          <div style={{ float: `left`, width: `33%`, padding: `0 0.5rem`}}>
            <div style={{maxWidth: `200px`, margin: `0 auto`}}>
              <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zb3VuZGNsb3VkLmNvbS91c2Vycy9zb3VuZGNsb3VkOnVzZXJzOjI5NjE1NTg5MC9zb3VuZHMucnNz" target="_blank" rel="noreferrer"><Google/></a>
            </div>
          </div>
          <div style={{ clear: `both`}}>
          </div>
        </div>
        <div style={{marginBottom: `2.9rem`}}>
          <h3 style={{textAlign: `center`}}>
          Bitcoin Cabana Podcast esplora Bitcoin, macroeconomia e le tesi di investimento che ne emergono. 
          </h3>
          <p>
          E' nato come salotto serale tra amici dove confrontarsi sulle news dal mondo e le mode del momento.. ogni riferimento a NFT, DeFi e blockchain è puramente casuale. 
          "Cabana" perché ci sono tante storie interessanti che legano Bitcoin a vari paradisi tropicali, a volte anche fiscali, se ti incuriosisce inizia ad ascolarci e partecipa ai live del lunedì sera, 21:00, sul canale <a href="https://t.me/bitcoincabana">Telegram</a>!
          </p>
        </div>
        <div style={{marginBottom: `2.9rem`, textAlign: 'center'}}>
          <h3 style={{textAlign: `center`}}>
          Ultimi Episodi 
          </h3>
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1755476958&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1748385951&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1741648893&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1734854895&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1728388578&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1721985600&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1728388578&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1715657412&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1696961715&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1691206848&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1685631315&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1679086821&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1673581029&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1667523642&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1661755065&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1655917524&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1650745101&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1645501938&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1638154740&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1634013801&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1626256695&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1622897559&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1617186054&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

          <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1610920545&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
          <div class="wrap-collabsible"> 
            <input id="collapsible" class="toggle" type="checkbox"/> 
            <label for="collapsible" class="lbl-toggle">Scorsa Stagione..</label>
            <div class="collapsible-content">
              <div class="content-inner">
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1721985600&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1583224087&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1577574630&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1571308057&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1564858882&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1558253506&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1553505193&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1547817970&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1541997832&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1533894940&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1528917805&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1522244071&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1516763347&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1510984036&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1506134224&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1500766960&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1497562279&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1492165471&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1486921921&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1480157275&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1474962133&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1470147946&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1465288780&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1459559422&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1453232038&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1447456573&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1442027047&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1436758972&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1432066507&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1426818286&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
                  
                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1421804539&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408045102&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>

                  <iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1402461712&color=%23ff5500&inverse=false&auto_play=false&show_user=true"></iframe>
          
                  
              </div>
            </div>
          </div>

         

        </div>
        <div style={{marginBottom: `2.9rem`}}>
          <h3 style={{textAlign: `center`}}>
          Chi siamo 
          </h3>
        </div>
        <div style={{marginBottom: `2.9rem`}}>
          <div style={{ float: `left`, width: `50%`, padding: `0 1rem 0 0` }}>
            <div>
              <div style={{maxWidth: `150px`, margin: `0 auto 1.45rem`}}>
                <Thomas/>
              </div>
              Thomas Rossi, <br/>
              Startup Founder, connoisseur di meme maximalisti.
            </div>
            <div style={{align:'center'}}>
            <a href="https://www.linkedin.com/in/thomasr0ssi/" target="_blank" rel="noreferrer"><LinkedIn/></a>
            <a href="https://twitter.com/th_s4m0ht" target="_blank" rel="noreferrer"><Twitter/></a>
            </div>


          </div>
          <div style={{ float: `left`, width: `50%`, padding: `0 0 0 1rem `}}>
            <div>
              <div style={{maxWidth: `150px`, margin: `0 auto 1.45rem`}}>
                <Federico/>
              </div>
              Federico Francolini, <br/>
              Managing Director, newyorker tornato nel bel paese. 
            </div>
            <div style={{align:'center'}}>
            <a href="https://www.linkedin.com/in/federico-francolini-7b04372a/" target="_blank" rel="noreferrer"><LinkedIn/></a>
            <a href="https://twitter.com/FedericoFrancol" target="_blank" rel="noreferrer"><Twitter/></a>
            </div>
          </div>
          <div style={{ clear: `both`}}>
          </div>
        </div>

        

      </div>

  </Layout>
)

export default IndexPage
